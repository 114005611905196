import { Button } from "@/Components/ui/button";
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "@/Components/ui/table";

export default function CartTable({ cart, onRemove = undefined }) {
    return <Table dense>
        <TableHeader>
            <TableRow>
                <TableHead className="w-fit">Size</TableHead>
                <TableHead className="text-center">Qty</TableHead>
                <TableHead className="text-center">Price</TableHead>
                <TableHead className="text-right">Total</TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            {cart.items.map((item) => (
                <TableRow key={item.id}>
                    <TableCell className="font-semibold">{item.size}</TableCell>
                    <TableCell className="text-center">{item.pivot.quantity}</TableCell>
                    <TableCell className="text-center">{item.pivot.total_price / item.pivot.quantity}</TableCell>
                    <TableCell className="text-right whitespace-nowrap">{item.pivot.total_price}</TableCell>
                </TableRow>
            ))}
        </TableBody>
        <TableFooter>
            <TableRow>
                {typeof onRemove === 'function' && <TableCell ><Button variant="destructive" size="xs" onClick={() => onRemove(cart)}>Remove</Button></TableCell>}
                <TableCell colSpan={typeof onRemove === 'function' ? 2 : 3}>Total</TableCell>
                <TableCell className="text-right whitespace-nowrap">$ {cart.total}</TableCell>
            </TableRow>
        </TableFooter>
    </Table>
}
